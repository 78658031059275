import * as React from "react";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";

import Breadcrumbs from "@components/Breadcrumbs";
import BlogNews from "@components/BlogNews";
import Seo from "@components/Seo";

import SortingBlog from "@page_components/blog/SortingBlog";

function BlogPage({ data }) {
  const { posts, categories, seo_data } = data;
  const { seo } = seo_data.nodes[0];
  const blog_data = {
    posts: posts.nodes,
    categories: categories.nodes,
  };
  const breadcrumbs_data = [{ name: "Blog", href: `/blog` }];
  const hero_blog_image = getImage(
    posts?.nodes[0]?.featuredImage?.node?.gatsbyImage
  );

  return (
    <>
      <Seo title={seo.title} description={seo.metaDesc} />
      <div className="blog-page">
        <div className="container">
          <Breadcrumbs data={breadcrumbs_data} />
          <main>
            <h1>Blog</h1>
            <BlogNews
              image={hero_blog_image}
              blog_news_data={posts.nodes[0]}
              variant="xl"
            />
          </main>
        </div>

        <section className="blog-page__list">
          <div className="container">
            <SortingBlog blog_data={blog_data} context="Wszystkie" />
          </div>
        </section>
      </div>
    </>
  );
}

export default BlogPage;

export const query = graphql`
  {
    seo_data: allWpPage(filter: { title: { eq: "Blog" } }) {
      nodes {
        seo {
          title
          metaDesc
        }
      }
    }

    posts: allWpPost(
      sort: { fields: date, order: DESC }
      filter: { language: { slug: { eq: "pl" } } }
    ) {
      nodes {
        title
        categories {
          nodes {
            name
            slug
          }
        }
        content
        excerpt
        date(formatString: "MM.DD.YYYY")
        slug
        categories {
          nodes {
            name
          }
        }
        featuredImage {
          node {
            gatsbyImage(
              width: 690
              height: 410
              quality: 100
              layout: FULL_WIDTH
            )
            altText
          }
        }
      }
    }

    categories: allWpCategory(
      filter: {
        name: { nin: ["Wszystkie", "Bez kategorii"] }
        language: { slug: { eq: "pl" } }
      }
    ) {
      nodes {
        name
        slug
      }
    }
  }
`;
