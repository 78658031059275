import "./styles.scss";

import React from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import { useLocale } from "@context/locale";

function BlogNews({ blog_news_data, image, variant }) {
  const { t, lang } = useLocale();
  const { slug, excerpt, date, title, categories, featuredImage } =
    blog_news_data;

  const format_date = new Date(date).toLocaleString(lang, {
    day: "numeric",
    month: "long",
    year: "numeric",
  });
  const excerpt_length = variant === "xl" ? 269 : 129;

  const cutExcerpt = (text, length) => {
    const cutted_excerpt = `${text.slice(3, length)}...`;
    return cutted_excerpt;
  };

  return (
    <Link
      to={lang === "en" ? `/en/blog/${slug}` : `/blog/${slug}`}
      className={variant ? `blog-news blog-news--${variant}` : "blog-news"}
    >
      <div className="blog-news__image">
        {!!image && (
          <GatsbyImage image={image} alt={featuredImage?.node?.altText || ""} />
        )}
        <div className="blog-news__type">{categories.nodes[0].name}</div>
      </div>
      <div className="blog-news__text">
        <span className="blog-news__date">{format_date}</span>
        <h5 className="blog-news__title">{title}</h5>
        <div
          className="blog-news__description"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: cutExcerpt(excerpt, excerpt_length),
          }}
        />
      </div>
      {variant === "l" && (
        <p className="blog-news__more">{t("Zobacz więcej")}</p>
      )}
    </Link>
  );
}

export default BlogNews;
