import "./styles.scss";

import { useLocale } from "@context/locale";
import React, { useState } from "react";
import { navigate } from "gatsby";

import { getImage } from "gatsby-plugin-image";

import BlogNews from "@components/BlogNews";
import FiltrLabel from "@components/FiltrLabel";
import Button from "@components/Button";

function SortingBlog({ blog_data, context = "Wszystkie", start_index = 1 }) {
  const { t, lang } = useLocale();
  const [current_category, setCurrentCategory] = useState(context);
  const [limit_blog_news, setLimitBlogNews] = useState(6);
  const { categories, posts } = blog_data;

  const handleSelect = (e) => {
    if (e.target.value === "all") {
      navigate(`/blog`);
    } else {
      navigate(`/blog/${e.target.value}/#!`);
    }
  };

  return (
    <div className="sorting-blog">
      <div className="sorting-blog__category">
        <p>{t("Zobacz wpisy z wybranej kategorii")}</p>
      </div>
      <div className="sorting-blog__label-list">
        <FiltrLabel
          name={t("Wszystkie")}
          link={lang === "en" ? "/en/blog/#!" : "/blog/#!"}
          handleChecked={() => setCurrentCategory("Wszystkie")}
          checked={current_category === "Wszystkie"}
        />
        {categories.map((category) => (
          <FiltrLabel
            name={category.name}
            link={
              lang === "en"
                ? `/en/blog/${category.slug}/#!`
                : `/blog/${category.slug}/#!`
            }
            handleChecked={() => setCurrentCategory(category.name)}
            checked={current_category === category.name}
          />
        ))}
      </div>
      <select
        name="procedures"
        id="procedures"
        className="sorting-blog__select"
        onChange={handleSelect}
      >
        <option value="current" selected>
          {current_category} &nbsp;
        </option>
        {current_category !== "Wszystkie" && (
          <option value="all">Wszystkie &nbsp;</option>
        )}
        {categories.map((category) => (
          <option value={category.slug}>{category.name}</option>
        ))}
      </select>

      <div className="blog-section__list" id="blogList">
        {posts.map((item, index) => {
          if (index <= limit_blog_news && index >= start_index) {
            const newsImage = getImage(item?.featuredImage?.node?.gatsbyImage);

            return (
              <BlogNews
                blog_news_data={item}
                image={newsImage}
                categories={item.categories.nodes}
                key={item.slug}
                variant="l"
              />
            );
          }
          return null;
        })}
      </div>
      {posts.length - 1 > limit_blog_news && (
        <Button
          onClick={() => setLimitBlogNews(limit_blog_news + 6)}
          className="sorting-blog__button"
        >
          {t("Pokaż więcej wpisów")}
        </Button>
      )}
    </div>
  );
}

export default SortingBlog;
