import "./styles.scss";

import React from "react";
import { Link } from "gatsby";

function FiltrLabel({ name, link, handleChecked, checked }) {
  return (
    <Link to={link} className="filtr-label">
      <input
        type="checkbox"
        name={name}
        id={name}
        onChange={handleChecked}
        checked={checked}
      />
      <label htmlFor={name}>{name}</label>
    </Link>
  );
}

export default FiltrLabel;
